<template>
	<div class="home" :style="{ height: containerHeight }">
		<div style="background-image: linear-gradient(#ff6034, #fff); padding-top: 5px; padding-bottom: 10px">
			<div style="color: #fff; padding-left: 10px; margin: 6px 0;position:relative;top:1px;" @click="showProxyPanel = true">
				<van-icon name="location-o" />
				<span style="font-size: 13px; position: relative; top: -3px; margin-left: 4px; margin-right: 2px">{{ proxyStg.originName||proxyStg.name }}</span>
				<van-icon :name="showProxyPanel ? 'arrow-up' : 'arrow-down'" />
			</div>
			<!-- <div style="height: 30px;"></div> -->
			<van-swipe class="my-swipe" :autoplay="2000" indicator-color="white">
				<van-swipe-item v-for="banner in bannerList" @click="gotoPage(banner.link)">
					<img :src="getImgUrl(banner.url)" style="width: 100%" />
				</van-swipe-item>
			</van-swipe>
		</div>

		<div style="background: #ffffff; margin: 2px 0px; border-radius: 5px">
			<van-search
				v-model="keyword"
				shape="round"
				:label="searchType == 1 ? '找厂家' : '找商品'"
				left-icon="arrow-down"
				show-action
				placeholder="输入关键字搜索"
				@focus="searchFocus = true"
				@blur="delayHideSearch"
				@click-left-icon="searchType = searchType == 1 ? 2 : 1"
			>
				<template #action>
					<div @click="onSearch">
						<van-image :src="imgs.searchPng" style="width: 18px; height: 18px; margin-left: 4px; position: relative; left: -6px; top: 4px" />
					</div>
				</template>
			</van-search>
			<!-- <van-search v-model="keyword" shape="round" show-action placeholder="找商品" @focus="searchFocus = true"
				show-action @blur="delayHideSearch">
				<template #action>
					<div @click="onSearch">搜索</div>
				</template>
			</van-search> -->
		</div>
		<van-grid class="category">
			<van-grid-item v-for="cate in shopCateList">
				<van-image style="padding: 0 10px 6px;" :src="getImgUrl(cate.imgUrl)" @click="openShopList(cate.cateId)" />{{cate.cateName}}
			</van-grid-item>
		</van-grid>
		<van-grid class="category" :border="false" :column-num="5">
			<van-grid-item v-for="shop in recommendShopList" :key="shop.shopId">
				<!-- <div style="position: relative" :style="{ background: 'url(' + imgs.ballPng + ')' }"> -->
				<div style="position: relative">
					<img :src="getImgUrl(shop.shopLogo)" style="border-radius: 50px; overflow: hidden; background-size: 100%" @click="gotoShop(shop.shopId)" />
				</div>
			</van-grid-item>
		</van-grid>
		<van-cell-group class="van-clearfix" style="margin: 5px 8px; background: #f7f7f7">
			<van-list v-model="loadMore" :immediate-check="false" :finished="finished" :offset="50" finished-text="没有更多了..." @load="getShopList">
				<div v-for="item in shopList1" is-link :key="item.shopId" :to="'/shop?id=' + item.shopId" style="background: #fff; margin: 8px 0; border-radius: 5px">
					<div style="display: flex; padding: 20px 20px 20px 20px" @click="gotoShop(item.shopId)" class="productline">
						<div style="width: 50px; height: 50px; border: 1px solid #ededed; overflow: hidden; border-radius: 50px">
							<img style="width: 50px; height: 50px; border-radius: 50px"  :src="getImgUrl(item.shopLogo)" />
						</div>
						<div style="flex-grow: 1; padding-left: 8px">
							<p class="van-ellipsis" style="margin: 0px 0 5px 4px; font-weight: bold; font-size: 18px; display: line-block" :style="{ color: item.level == 3 ? '#D51C20' : '#000' }">
								{{ item.shopName }}
							</p>
							<div>
								<span style="color: #999999; background: #f7f7f7; margin: 0 4px; padding: 2px 5px; font-size: 13px" v-for="tag in item.tags" :key="tag">
									{{ tag }}
								</span>
							</div>
						</div>
						<img :src="imgs['level' + item.level]" style="width: 40px; height: 40px" />
					</div>
					<div class="prolist" style="display: none;;">
						<p class="watchicon">
							<span class="">
								{{ item.viewNum }}
							</span>
							次浏览
						</p>
						<!-- <span class="borderone" @click="gotoShop(item.shopId,1)">产品介绍</span> -->
						<span class="bordertwo">
							<a style="color: #fff" :href="'tel:' + item.shopPhone">电话咨询</a>
						</span>
					</div>
				</div>
				<div
					v-for="(item, shopIndex) in shopList2"
					:class="{ level1shop: shopIndex % 2 == 1 }"
					is-link
					:key="item.shopId"
					:to="'/shop?id=' + item.shopId"
					style="background: #fff; position: relative; margin-bottom: 8px;height: 160px; border-radius: 5px; display: inline-block; width: 48%">
					<div style="position: absolute; top: 5px; right: 5px">
						<van-image :src="imgs['level' + item.level]" style="width: 25px; height: 25px" />
					</div>
					<div style="text-align: center; min-height: 20px" class="productline"  @click="gotoShop(item.shopId)">
						<div style="width: 50px; height: 50px; border-radius: 50px; border: 1px solid #ededed; overflow: hidden; margin: 0 auto" @click="gotoShop(item.shopId)">
							<van-image style="width: 50px; height: 50px; border-radius: 50px" :src="getImgUrl(item.shopLogo)" />
						</div>
						<div style="flex-grow: 1; margin: 0 auto; text-align: center;;">
							<p class="line1" style="margin: 8px 0 12px 4px; font-weight: bold; font-size: 18px">{{ item.shopName }}</p>
							<div style="min-height: 20px;display: flex;overflow: hidden;margin-right: 5px;padding:8px 0 10px;border-top: 1px solid #ededed">
								<span style="font-size: 13px; color: #999999; background: #f7f7f7;flex-shrink: 0; margin: 0 3px; padding: 2px 4px" v-for="tag in item.tags" :key="tag">
									{{ tag }}
								</span>
							</div>
						</div>
						<p class="watchicontwo"  style="display: none;;">
							<span class="">
								{{ item.viewNum }}
							</span>
							次浏览
						</p>
					</div>
					<div style="text-align: right; padding: 15px 10px; font-size: 13px; display: none; justify-content: flex-end">
						<!-- <span class="borderone" @click="gotoShop(item.shopId,1)">产品介绍</span> -->
						<span class="bordertwo" >
							<a style="color: #fff" :href="'tel:' + (item.shopPhone || '')">电话咨询</a>
						</span>
					</div>
				</div>
				
			</van-list>
		</van-cell-group>
		<div style="height: 0;">
			<baidu-map class="map" @ready="onLocation" center="北京" :zoom="15" @locationSuccess="onLocation" @locationError="onLocation">
			  </baidu-map>
		</div>
		<div style="text-align: center; font-size: 12px; color: #6d6d6d; padding: 10px 0px">沪ICP备18039025号-1</div>
		<bottomTabNav :index="0" />
		<div class="orderMsg" :class="{ slideUp: slideUpMsg }" v-show="showOrderMsg">
			<van-icon name="bell" style="top: 2px" />
			{{ orderMsg }}
		</div>
		<van-action-sheet v-model="showProxyPanel" :actions="proxyList" cancel-text="取消" close-on-click-action @cancel="showProxyPanel = false" @select="onSelectProxy" />
	</div>
</template>
<script>
import bottomTabNav from '@/components/bottomTabNav';
import request from '@/utils/request';

import level1Png from '@/assets/index/level1.png';
import level2Png from '@/assets/index/level2.png';
import level3Png from '@/assets/index/level3.png';
import searchPng from '@/assets/index/search.png';
import ballPng from '@/assets/index/ball.png';
import wx from 'weixin-js-sdk';
import BaiduMap from 'vue-baidu-map';
import { Toast } from 'vant';
export default {
	name: 'home',
	components: {
		bottomTabNav
	},
	data() {
		return {
			imgs: {
				level0: level1Png,
				level1: level1Png,
				level2: level2Png,
				level3: level3Png,
				searchPng: searchPng,
				ballPng: ballPng
			},
			proxyStg: {
				name: ''
			},
			keyword: '',
			active: 0,
			containerHeight: 0,
			goodsLogoSize: 0,
			bannerList: [],
			cateList: [],
			shopCateList: [],
			hotList: [],
			goodsList: [],
			searchFocus: false,
			showOrderMsg: false,
			slideUpMsg: false,
			orderMsg: '',
			pageNum: 1,
			pageSize: 20,
			isRequesting: false,
			loading: false,
			finished: false,
			loadMore: false,
			recommendShopList: [],
			isRequestingShopList: false,
			shopList1: [],
			shopList2: [],
			showProxyPanel: false,
			proxyList: [],
			BMap: null,
			position:null,
			searchType: 1
		};
	},
	computed: {},
	created() {
		this.goodsLogoSize = document.documentElement.clientWidth / 2 - 25 + 'px';
		this.containerHeight = document.documentElement.clientHeight - 52 + 'px';
		this.getProxyStgList();
		this.getDefaultProxyStg();
		this.getList();
		this.updateWxShareConfig();
	},
	mounted(){
		// this.updateWxShareConfig();
	},
	watch: {
		$route(to, from) {			
			this.updateWxShareConfig();
		}
	},
	methods: {
		updateWxShareConfig(){
			this.refreshWxConfig(wx,()=>{
				let share_config = {
					imgUrl: location.origin + "/fj_logo.jpg", 
					title:"福鲸商城",
					desc: "福鲸冻品集采平台 \n 全国冻品厂家批发平台，一件也是批发价。", 
					link: location.href, 
					success: function(res) {
						//alert(JSON.stringify(res));
					}
				}
				wx.updateAppMessageShareData(share_config); 
				wx.updateTimelineShareData(share_config); 
			});
		},
		onLocation(e) {
			this.BMap = e.BMap;
			if (this.BMap) {
				this.refreshLocation(this.BMap,res => {
				  this.position = res.point;
				  this.refreshProxyDistance();
				})
			}
		},
		  
		    
		openShopList(cateId) {
		  this.$router.push({ path: "/shopList", query: { cateId: cateId } });
		},
		gotoShop(id,tabId) {
		  this.$router.push({ path: "/shop", query: { id: id ,t:tabId||0} });
		},
		getProxyStgList() {
			request.post('/api/proxyStg/list').then((res) => {
				this.proxyList = res;
				this.refreshProxyDistance();
			});
		},
		refreshProxyDistance(){
			if(!this.proxyList||this.proxyList.length<1||!this.position){
				return;
			}
			this.each(this.proxyList,(i,item)=>{
				if(!item.lat||!item.lon){
					return;
				}
				item.distance=this.mapUtil.distance(this.position.lat,this.position.lng,item.lat,item.lon)
				if(!item.distance){
					return;
				}
				item.distance=parseInt(item.distance);
				item.originName=item.name;
				let distanceDesc=item.distance+"米";
				if(item.distance>1000){
					distanceDesc=parseInt(item.distance/1000)+"公里";
				}
				item.name=item.name+"（"+distanceDesc+"）";
			});
			let allProxy=this.proxyList.splice(0,1);
			let proxyList=this.proxyList.sort(function(i1,i2){
				if(!i1.distance){
					return -1;
				}else{
					return i1.distance>i2.distance?1:-1;
				}
			});
			this.proxyList=allProxy.concat(proxyList);
		},
		onSelectProxy(item) {
			this.storage.set('proxyStg', JSON.stringify(item));
			this.getDefaultProxyStg();
		},
		delayHideSearch() {
			setTimeout(() => {
				this.searchFocus = false;
			}, 600);
		},
		getDefaultProxyStg() {
			let proxyStg = this.storage.get('proxyStg');
			if (!proxyStg) {
				request.post('/api/proxyStg/latest').then((rs) => {
					if (rs.success) {
						this.proxyStg = rs.data;
						this.storage.set('proxyStg', JSON.stringify(rs.data));
						this.getShopList(true);
					} else {
						Toast.fail(rs.msg);
					}
				});
			} else {
				this.proxyStg = JSON.parse(proxyStg);
				this.getShopList(true);
			}
		},
		getList() {
			let that = this;
			request.post('/api/index/initData').then((res) => {
				if (res.code == 0) {
					let data = res.data;
					if (data) {
						let jsonData = JSON.parse(data.jsonData);
						that.bannerList = jsonData.bannerList;
						that.cateList = jsonData.cateList;
						that.hotList = jsonData.hotList;
						that.shopCateList = jsonData.shopCateList;
						if (jsonData.shopList) {
							jsonData.shopList.sort(function (item1, item2) {
								return parseInt(item2.shopSort) - parseInt(item1.shopSort);
							});
							that.recommendShopList = jsonData.shopList;
						}
						that.goodsList = data.goodsList;
					}
				} else {
					Toast.fail(res.msg);
				}
			});
		},
		refreshGoodsList() {
			if (this.isRequesting) {
				return;
			} else {
				this.isRequesting = true;
			}
			request
				.post('/api/goods/list', {
					sort: this.sort,
					proxyId: this.proxyStg.id,
					keyword: this.keyword,
					shopId: this.shopId || '',
					pageNum: this.pageNum++,
					pageSize: this.pageSize
				})
				.then((rs) => {
					this.loading = false;
					this.isRequesting = false;
					if (rs.rows.length < this.pageSize || rs.total <= (this.pageNum - 1) * this.pageSize) {
						this.finished = true;
					}

					this.goodsList = this.goodsList.concat(rs.rows);
				});
		},
		onSearch() {
			var routePath = this.searchType == 1 ? '/shopList' : '/goodsList';

			this.$router.push({
				path: routePath,
				query: {
					keyword: this.keyword
				}
			});
		},
		openCate(keyword) {
			this.$router.push({
				path: '/goodsList',
				query: {
					keyword: keyword
				}
			});
		},
		gotoPage(link) {
			if (link) {
				this.$router.push({
					path: link
				});
			}
		},

		
		getShopList(reload) {
			if (reload) {
				this.pageNum = 1;
				this.finished = false;
				this.shopList1=[];
				this.shopList2=[];
			}
			//发送请求数据
			const db = {
				// queryText:this.queryText,
				pageSize: this.pageSize,
				pageNum: this.pageNum,
				proxyId: this.proxyStg.id
			};
			if (!this.finished) {
				this.doGetShopList('/api/shop/queryShopList', db);
			}
		},
		doGetShopList(url, db) {
			if(this.isRequestingShopList){
				return;
			}
			this.isRequestingShopList=true;
			let that = this;
			request.post(url, db).then((res) => {
				this.isRequestingShopList=false;
				if (res.code === 0) {
					if (res.data.length > 0) {
						let shopList1 = that.shopList1,
							shopList2 = that.shopList2;

						for (let i = 0; i < res.data.length; i++) {
							let shop = res.data[i];
							shop.tags = (shop.tags || '').split(',');
							for (let i = shop.tags.length - 1; i >= 0; i--) {
								if (!shop.tags[i]) {
									shop.tags.splice(i, 1);
								}
							}
							shop.level = shop.level || '0';
							if (parseInt(shop.level) < 30) {
								shopList2.push(shop);
							} else {
								shopList1.push(shop);
							}
						}
						that.shopList1 = shopList1;
						that.shopList2 = shopList2;
					}
					//最后一页
					if (!res.hasNextPage) {
						that.finished = true;
						that.loadMore = false;
					} else {
						that.finished = false;
						that.loadMore = false;
						that.pageNum += 1;
					}
				} else {
					that.finished = true;
				}
			});
		}
	}
};
</script>
<style type="text/css" scoped="">
.home {
	width: 100%;
	height: 1000px;
	overflow: auto;
	background: #f5f5f5;
}
.van-cell-group .van-list{
	display: flex;
	flex-wrap: wrap;
}

.van-grid-item__content {
	padding: 5px !important;
}

.van-search__content--round {
	border: 0px;
}

.my-swipe {
	margin: 0px 10px;
	border-radius: 10px;
}

.my-swipe img {
	width: 100%;
	height: 180px;
}

.category {
	margin: 0;
	font-size: 14px;
}

.category img {
	width: 50% !important;
}

.hotDot {
	padding: 5px 10px 5px 70px;
	position: relative;
}

.hotDot img {
	height: 18px;
	position: relative;
	top: -2px;
}

.hotDot > span {
	border-right: 1px solid #6d6d6d;
	padding-right: 8px;
	position: absolute;
	left: 24px;
	top: 17px;
	height: 15px;
}

.van-icon-volume-o.van-icon {
	font-size: 20px;
	position: relative;
	top: -2px;
	margin-right: 5px;
}

.koubei img {
	width: 80%;
}

.goodsLogoWrap {
	line-height: 100%;
	position: relative;
	height: auto !important;
	overflow: hidden;
}

.goodsLogoWrap img {
	border-radius: 6px;
}

.van-image img {
	margin: 0px auto;
}

.van-image__error,
.van-image__img,
.van-image__loading {
	margin: 0px auto;
}

.orderMsg {
	position: fixed;
	top: 50px;
	padding: 0.5em 1.5em 0.5em 1em;
	background: #362424;
	transition: all 1s;
	color: #fff;
	border-radius: 10em;
	margin-left: 10px;
}

.orderMsg.slideUp {
	opacity: 0;
	top: 35px;
}

.goodsLogoWrap .van-tag {
	position: absolute;
	top: 1px;
	left: 1px;
	z-index: 10;
}

.van-image__img {
	margin: 0px auto !important;
}

.van-image01 {
	height: 120px;
	width: 160px;
}
.prolist {
	text-align: right;
	padding: 15px 10px;
	font-size: 14px;
	position: relative;
}

.prolist .watchicon {
	position: absolute;
	top: 0px;
	color: #999;
}
</style>
<style>
.van-list__finished-text{
	width: 100%;
}
.level1shop {
	margin-left: 3%;
}

.productline {
	position: relative;
	padding-bottom: 0px;
	padding-top: 20px;
}

.productline:after {
	content: ' ';
	position: absolute;
	bottom: 0px;
	left: 10px;
	right: 10px;
	height: 1px;
	display: none;
	background: #ededed;
}

.borderone {
	color: #d51c20;
	border: 1px solid #ededed;
	border-radius: 5px;
	display: line-block;
	line-height: 26px;
	display: inline-block;
	padding: 0px 6px;
}

.bordertwo {
	background-color: #d51c20;
	color: #ffffff;
	border: 1px solid #d51c20;
	margin-left: 6px;
	border-radius: 5px;
	line-height: 26px;
	display: inline-block;
	padding: 0px 6px;
}

.watchicontwo {
	text-align: left;
	padding-left: 10px;
	color: #999;
	padding-left: 10px;
	font-size: 14px;
	padding-bottom: 0px;
	border-top: 1px solid #ededed;
	padding-top: 12px;
}
</style>
